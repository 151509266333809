export const environment = {
  AppID: 20,
  production: true,
  host: 'https://app-test.loctite-pulse.com/ems/',
  installation_agent_url: 'https://app-test.loctite-pulse.com/ia',
  rm_parameter_key: 'cc_rm_param',
  debugMode: false,
  taskLimit: 4000,
  locale: 'en',
  debugAuth: false,
  href: '/em',
  appLogo: './assets/images/LoctitePulseLogo.svg',
  hasBusinessCentral: false,
  credentialLogin: true,
  portalLogin: false,
  msalLogin: true,
  msalPopup: false,
  msalClientID: '5dc58c9b-6c4b-426f-b651-10ab19aa9c71', // henkel
  msalIssuerService: 'https://login.microsoftonline.com/',
  msalIssuerServiceTenant: 'organizations',
  debugCredentials: null,
  // wp stuff:
  authorization_uri: '',
  redirect_uri: 'https://app-test.loctite-pulse.com/em/login',
  logout_uri: 'https://www.ccsmartequipment.com/',
  client_id: 'cc_loctite_pulse',
  fakeTelemetry: false,
  //isTestInstance: true,
  environmentInstanceName: 'TEST',
  disableTabletView: false,
  disableSignup: false,
  disableSelectedSteamRateCurrency: false,
  mapbox: {
    satelliteView:
      'https://api.mapbox.com/styles/v1/fbannier/ckseazcz54luo18rhr2sefnlh/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZmJhbm5pZXIiLCJhIjoiY2tlMnM0NnllMGM4NzM3bjdqY2hnanRxMiJ9.l9JR-r7QEflf6fNE6hIxFw',
    mapView:
      'https://api.mapbox.com/styles/v1/fbannier/ckna87y6l35an17o5bfy7ehkq/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZmJhbm5pZXIiLCJhIjoiY2tlMnM0NnllMGM4NzM3bjdqY2hnanRxMiJ9.l9JR-r7QEflf6fNE6hIxFw',
    geoCodingToken:
      'pk.eyJ1IjoiZmJhbm5pZXIiLCJhIjoiY2tlMnM0NnllMGM4NzM3bjdqY2hnanRxMiJ9.l9JR-r7QEflf6fNE6hIxFw',
  },
};
